/* .ap {
  scroll-behavior: smooth;
} */
.vh {
  height: 100vh;
}
.vh2 {
  height: 50vh;
}
.App {
  text-align: center;
}
.MuiToolbar-gutters {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.bgC {
  background-color: #fdeff0;
  height: 305px;
  margin-top: -455px;
}
.bgC2 {
  background-color: #fdeff0;
  height: 550px;
  margin-top: -250px;
}
.backgroudPng {
  background-repeat: no-repeat;
}
.lowerD {
  position: absolute;
  /* overflow: hidden; */
  left: 0%;
  right: 0%;
  /* top: 0%; */
  /* bottom: 0%; */
  /* z-index: 100; */
  z-index: -1;
}
.lowerD2 {
  position: absolute;
  /* overflow: hidden; */
  /* left: 0%; */
  right: 0%;
  /* top: 0%; */
  /* bottom: 0%; */
  /* z-index: 100; */
  z-index: -1;
}
.upperD {
  position: absolute;
  top: 362px;
  right: 0%;
  /* width: 981px; */
  height: 668px;
  /* left: 1249px; */
  /* opacity: 0.07; */
  z-index: -1;
}
.index {
  /* position: relative;/
   */
  /* margin-bottom: 50px; */
  /* z-index: -50; */
}
@media (max-width: 660px) {
  .vh {
    /* height: 0vh; */
  }
  .bgC {
    /* height: 0vh; */
  }
  .MuiToolbar-gutters {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .upperD {
    display: none !important;
  }
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
