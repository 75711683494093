.card1 {
  background: #ffffff;
  border: 1px solid #e9e9e9;
  box-sizing: border-box;
  border-radius: 20px;
  height: auto;
  padding: 20px;
  margin-top: 40px !important;
  box-shadow: 0px 35px 50px rgba(0, 0, 0, 0.08);
  text-align: right;
  /* margin-bottom: 300px !important; */
  margin-left: 5% !important;
  /* margin-right: 1% !important; */
  width: 90%;
}
.t1 {
  font-family: Inter !important;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 16px !important;
  line-height: 24px !important;

  letter-spacing: 0.01em !important;
  /* text-align: right; */
  color: #000000;
}
.t2red {
  font-family: Inter !important;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 16px !important;
  line-height: 24px !important;
  letter-spacing: 0.01em !important;

  color: #d30e28;
}
.bgClr {
  /* margin-top: 100px !important; */
  background-color: #f0f0f0;
  height: 250px;
}
.iconsFix {
  margin-top: -45px !important;
  float: left;
}
.pMar {
  line-height: 28px !important;
}
