.card2 {
  background: #ffffff;
  border: 1px solid #e9e9e9;
  box-sizing: border-box;
  border-radius: 20px;
  height: auto;
  padding: 20px;
  margin-top: 5px;
  box-shadow: 0px 25px 50px rgba(0, 0, 0, 0.12);
  text-align: right;
}
.t2 {
  font-family: Inter !important;
  font-style: normal;
  font-weight: 900 !important;
  font-size: 25px !important;
  /* line-height: 30px !important; */
  letter-spacing: 0.01em !important;
  text-transform: uppercase !important;

  color: #e5122e;
}
.p1 {
  font-family: Inter !important;
  font-style: normal !important;
  font-weight: 900 !important;
  font-size: 40px !important;
  line-height: 55px !important;
  letter-spacing: 0.01em !important;

  color: #273b4a;
}
.planP {
  font-family: Inter !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 20px !important;
  line-height: 43px !important;
  /* or 215% */

  letter-spacing: 0.01em !important;

  color: #273b4a;
}
