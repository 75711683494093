.typo3 {
  font-family: Inter !important;
  font-style: normal !important;
  font-weight: 900 !important;
  font-size: 40px !important;
  line-height: 48px !important;
  text-align: center !important;
  letter-spacing: 0.01em !important;

  color: #273b4a;
}
.slick-prev:before,
.slick-next:before {
  color: #273b4a;
}
.slickImg {
  border-radius: 20px;
  height: 300px;
}
.slick2Img {
  border-radius: 20px;
  margin-top: 20px;
}
.titleP {
  font-family: Inter !important;
  font-style: normal !important;
  font-weight: 900 !important;
  font-size: 30px !important;
  line-height: 48px !important;
  letter-spacing: 0.01em !important;

  color: #273b4a;
}
@media (max-width: 600px) {
  .slick2Img {
    width: 100%;
    height: 300px;
  }
  .alignCenter {
    text-align: center;
  }
}
