.big {
  background: #0e232d;
  border-radius: 20px;
  height: auto;
  margin-top: -190px;
}
.typo2 {
  font-family: Inter !important;
  font-style: normal !important;
  font-weight: 900 !important;
  font-size: 40px !important;
  line-height: 48px !important;
  letter-spacing: 0.01em !important;

  color: #ffffff;
}
.marginTop {
  margin-top: 60px;
}
@media (max-width: 969px) {
  .typo2 {
    font-size: 30px !important;
    margin-bottom: 40px !important;
  }
  .marginTop {
    margin-top: 90px;
  }
}
