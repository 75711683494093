/* .mirror {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
} */
.typo5 {
  font-family: Inter !important;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 20px !important;
  line-height: 22px !important;
  margin-top: 10px !important;
  color: #0e232d;
  /* margin-left: 19px !important; */
}
.typo10 {
  font-family: Inter !important;
  font-style: normal !important;
  font-weight: 900 !important;
  font-size: 40px !important;
  line-height: 48px !important;
  text-align: right !important;
  letter-spacing: 0.01em !important;

  color: #273b4a;
}

.urdu {
  text-align: right;
}
.hIcon {
  margin-top: 20px;

  margin-left: 20px !important;
}
.p {
  margin-left: 19px;
}
@media (max-width: 920px) {
  .mirror {
    display: none;
  }
  .typo10 {
    text-align: center !important;
  }
  .msgAlign {
    margin-right: 12px;
  }
}
