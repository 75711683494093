.big2 {
  background: #0e232d;
  border-radius: 20px;
  height: auto;
  /* z-index: 1000; */
}
.typo6 {
  font-family: Inter !important;
  font-style: normal !important;
  font-weight: 900 !important;
  font-size: 40px !important;
  line-height: 48px !important;
  text-align: center !important;
  letter-spacing: 0.01em !important;
  margin-top: 40px;
  color: #ffffff;
}
.txtF {
  background-color: #f7f7f7;
  border-radius: 5px;
}
form {
  display: contents !important;
}
#root > div:nth-child(10) > div > div > div:nth-child(3) > div > div > p > a {
  text-decoration: none;
  background-color: skyblue;
  width: 40px;
  border-radius: 10px;
  padding: 10px;
  color: white !important;
}
@media (max-width: 660px) {
  .big2 {
    /* margin-top: 650px; */
  }
}
