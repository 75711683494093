/* .logo {
  position: absolute;
  margin-left: 7.2%;
  right: 71.3%;
  top: 1.8%;
  bottom: 98.67%;
  margin-top: 6px;
} */
.v1_12 {
  width: 52px;
  color: rgba(211, 14, 40, 1);
  position: absolute;
  top: 27px;
  left: 490px;
  font-family: Inter;
  font-weight: Bold;
  font-size: 18px;
  opacity: 1;
  text-align: left;
}
.v2_401 {
  width: 46px;
  color: rgba(39, 59, 74, 1);
  position: absolute;
  top: 4255px;
  left: 1000px;
  font-family: Inter;
  font-weight: Medium;
  font-size: 16px;
  opacity: 1;
  text-align: left;
}
.v2_13 {
  width: 49px;
  color: rgba(211, 14, 40, 1);
  position: absolute;
  top: 27px;
  left: 570px;
  font-family: Inter;
  font-weight: Bold;
  font-size: 18px;
  opacity: 1;
  text-align: left;
}
.v2_15 {
  width: 40px;
  color: rgba(211, 14, 40, 1);
  position: absolute;
  top: 27px;
  left: 650px;
  font-family: Inter;
  font-weight: Bold;
  font-size: 18px;
  opacity: 1;
  text-align: left;
}
.v2_16 {
  width: 63px;
  color: rgba(211, 14, 40, 1);
  position: absolute;
  top: 27px;
  left: 725px;
  font-family: Inter;
  font-weight: Bold;
  font-size: 18px;
  opacity: 1;
  text-align: left;
}
.v2_17 {
  width: 41px;
  color: rgba(211, 14, 40, 1);
  position: absolute;
  top: 27px;
  left: 820px;
  font-family: Inter;
  font-weight: Bold;
  font-size: 18px;
  opacity: 1;
  text-align: left;
}
.v2_19 {
  width: 115px;
  height: 37px;
  background: rgba(211, 14, 40, 1);
  opacity: 1;
  position: absolute;
  top: 20px;
  left: 1166px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  overflow: hidden;
}
.v2_18 {
  width: 90px;
  color: rgba(255, 255, 255, 1);
  position: absolute;
  top: 28px;
  left: 1177px;
  font-family: Inter;
  font-weight: Semi Bold;
  font-size: 13px;
  opacity: 1;
  text-align: center;
}
.v2_827 {
  width: 115px;
  height: 37px;
  /* background: url('../images/v2_827.png'); */
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  opacity: 1;
  position: absolute;
  top: 20px;
  left: 1298px;
  overflow: hidden;
}
.v2_20 {
  width: 115px;
  height: 37px;
  background: rgba(14, 35, 45, 1);
  opacity: 1;
  position: relative;
  top: 0px;
  left: 0px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  overflow: hidden;
}
.v2_21 {
  width: 90px;
  color: rgba(255, 255, 255, 1);
  position: absolute;
  top: 8px;
  left: 11px;
  font-family: Inter;
  font-weight: Semi Bold;
  font-size: 13px;
  opacity: 1;
  text-align: center;
}
