.MuiSvgIcon-root {
  color: black;
}
.logo {
  /* text-align: left; */
  /* position: absolute; */
  /* margin-left: 25.2%; */
  height: '36px' !important;
  /* right: 71.3%; */
  /* top: 1.8%;
  bottom: 98.67%; */
  /* margin-top: -6px; */
}
.tt {
  font-family: Inter;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 18px !important;
  line-height: 22px !important;
  /* identical to box height */
  /* margin-left: 20px; */
  color: #d30e28;
  /* margin-top: 30px; */
}
.anchor {
  text-decoration: none;
  color: #d30e28;
  font-weight: 700 !important;
}
@media (max-width: 600px) {
  .sectionMobile {
    display: none;
  }
}
.react-switch-bg {
  height: 30px !important;
}
