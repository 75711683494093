
.typo1 {
  font-family: Inter !important;
  /* font-style: normal !important; */
  font-weight: 900 !important;
  font-size: 48px !important;
  line-height: 58px !important;
  letter-spacing: 0.01em !important;
  text-align: right;
  color: #0e232d !important;
}
.lobImg {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}
@media (max-width: 920px) {
  .lobImg {
    display: none;
  }
  .typo1 {
    font-size: 38px !important;
  }
}
